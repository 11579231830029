import React from "react";

import style from "../../styles/contact.module.css";
import Addresses from "./Components/Addresses/Addresses";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <>
      <div className={style.contactUsContainer}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>AICO</title>
          <meta
            name="description"
            content="Reach out to us today at any of our offices via email or phone call, and we would get back to you."
          />
          <meta name="keywords" content="Contact us" />
          <meta name="author" content="Abdullahi Ibrahim and Co AICO" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <h1>CONTACT US</h1>
        <Addresses />
        <div className={style.contactForm}>
          {/* <h2>Send us a  message</h2> */}
          {/* <Form handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} style={style} loading={loading} data={data}/> */}
        </div>
      </div>
    </>
  );
}
export default Contact;
