import Image2 from "../images/com/carousel2.jpeg";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 1,
    image: "https://i.ibb.co/Q8wjRLr/wooden-gavel-1.jpg",
    context: "Integrity",
    text: "The Firm consists of a strong and proven team of practitioners in the varied fields of law with a combination of more than a century’s wealth of experience in providing adequate legal solutions with the utmost integrity in litigation & advisory services in several fields of law including emerging global trends.",
  },
  {
    id: 2,
    image: Image2,
    context: "Wealth of Experience",
    text: "",
  },
  {
    id: 3,
    image: Image,
    context: "Licensed Accredited Agent",
    text: "We are a LICENSED ACCREDITED AGENT for the MINISTRY OF INTERIOR  in NIGERIA, for applications and permits for Expatriate Quota, Formation of Statutory Marriages and related matters, Grant of Citizenship, Special Immigration Status, Temporary Residency Permits, & Licensing of Public Places of Worship",
  },
];
