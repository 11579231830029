import React from "react";

import ImageGallery from "react-image-gallery";
import people from "../../images/home.jpg";

function Gallery() {
  const arr = [
    {
      original: people,
    },
  ];
  return (
    <div style={{ height: "100vh", marginBottom: "80px" }}>
      <div style={{ padding: "40px", paddingTop: "90px" }}>
        <h3 style={{ paddingLeft: "120px" }}> Gallery</h3>
        <ImageGallery items={arr} />
      </div>
    </div>
  );
}

export default Gallery;
