import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import { Newss, Publicationss } from "../data/publication";

const Section = styled.section`
  background: #fff;
`;

const Wrapper = styled.div`
  padding: 7rem 0 0;
  margin: 0 2rem;

  @media screen and (min-width: 1025px) {
    margin: 0 7rem;
  }
`;

const Links = styled(Link)`
  color: #333;
  text-decoration: underline !important;
  position: absolute;
  bottom: 0;

  @media screen and (max-width: 1100px) {
    bottom: -33px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 3em;

  h1 {
    color: var(--textColor);
    font-weight: 600;
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    h1 {
      font-size: 22px !important;
    }
  }
`;

const NewsContent = styled.div`
  position: relative;
  margin: 3em 0 8em;

  h2 {
    width: 100%;
    font-weight: 600;
    margin: 20px 0;
  }

  p {
    line-height: 2;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    h2 {
      font-size: 1.2em !important;
    }

    p {
      font-size: 12px;
    }
  }
  @media screen and (min-width: 1101px) {
    margin: 3em 0 4em;
    h2 {
      width: 40%;
    }

    p {
      width: 90%;
    }
  }
`;

const Blog = styled.div`
  div:last-child {
    text-align: center;
  }

  div:last-child img {
    width: 60%;
  }
  @media screen and (min-width: 1101px) {
    display: flex;
    div:last-child {
      text-align: initial;
    }

    div:last-child img {
      width: inherit;
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;

    div:first-child {
      margin: 1em 0 0;
    }
    div:last-child img {
      width: 100%;
    }
  }
`;

const NewsContainer = styled.div`
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const Mobile = styled.div`
  @media screen and (min-width: 501px) {
    display: none;
  }
`;

const PublicationCard = styled.div`
  background: #ffffff;
  border-radius: 3.94839px;
  box-shadow: 1px 1px 9px #ccc;

  div {
    padding: 0.7em;
  }

  a {
    color: #333 !important;
  }

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 1.3em !important;
    }

    p {
      font-size: 11px;
    }
  }
`;

const DesktopH2 = styled.h2`
  font-size: 1.3rem !important;
`;
const Img = styled.img`
  width: 100%;
`;

const News = ({
  active,
  activeP,
  showPublication,
  showNew,
  formData,
  setFormData,
}) => {
  const [show, setShow] = useState(false);
  const [mobile] = useState(formData.event);
  const [ids, setId] = useState();

  const handleShowNews = (id) => {
    const newA = Newss?.find((i) => i.id === id);
    setId(newA);
    if (newA?.id === id) {
      setShow(true);
    }
    if (show && newA?.id === id) {
      setShow(false);
    }
  };

  return (
    <Section>
      <Wrapper>
        <ContentWrapper>
          <h1 style={{ marginBottom: "1em" }}>Publication</h1>
          <Row>
            {Publicationss.map((item, index) => {
              return (
                <Col sm={6} lg={6} xl={4} style={{ marginBottom: "3em" }}>
                  <PublicationCard>
                    <Img src={item.image} alt="" />
                    <div>
                      <DesktopH2
                        style={{
                          textTransform: "capitalize",
                          fontSize: "1.4rem !important",
                        }}
                      >
                        {item.title}
                      </DesktopH2>
                      <p>{item.paragraph}</p>
                      <Link to={`/events/publication/${item.id}`}>
                        {item.label}
                      </Link>
                    </div>
                  </PublicationCard>
                </Col>
              );
            })}
          </Row>
        </ContentWrapper>

        {/* MOBILE VIEW */}
        <Mobile>
          {mobile === "news" ? (
            <ContentWrapper>
              {Newss.map((item, index) => {
                return (
                  <NewsContent key={index}>
                    <h2>{item.title}</h2>
                    <Blog>
                      <div>
                        <p>{item.paraOne}</p>
                        <p>{item.paraTwo}</p>
                        <p>{item.paraThree} </p>
                        {show === true && ids?.id === item.id ? (
                          <p>{item.paraFour}</p>
                        ) : (
                          ""
                        )}
                        <Links to="#" onClick={() => handleShowNews(item.id)}>
                          {show === true ? "Read Less" : "Read More"}
                        </Links>
                      </div>
                      <div>
                        <img src={item.image} alt="" />
                      </div>
                    </Blog>
                  </NewsContent>
                );
              })}
            </ContentWrapper>
          ) : (
            <ContentWrapper>
              <h1 style={{ marginBottom: "1em" }}>Publication</h1>
              <Row>
                {Publicationss.map((item, index) => {
                  return (
                    <Col sm={6} lg={6} xl={4} style={{ marginBottom: "3em" }}>
                      <PublicationCard>
                        <Img src={item.image} alt="" />
                        <div>
                          <h2>{item.title}</h2>
                          <p>{item.paragraph}</p>
                          <Link>{item.label}</Link>
                        </div>
                      </PublicationCard>
                    </Col>
                  );
                })}
              </Row>
            </ContentWrapper>
          )}
        </Mobile>

        {/* DESKTOP VIEW */}
        <NewsContainer>
          {active && (
            <ContentWrapper>
              {Newss.map((item, index) => {
                return (
                  <NewsContent key={item.id}>
                    <h2>{item.title}</h2>
                    <Blog>
                      <div>
                        <p>{item.paraOne}</p>
                        <p>{item.paraTwo}</p>
                        <p>{item.paraThree} </p>
                        {show === true && ids?.id === item.id ? (
                          <p>{item.paraFour}</p>
                        ) : (
                          ""
                        )}
                        <Links to="#" onClick={() => handleShowNews(item.id)}>
                          {show === true ? "Read Less" : "Read More"}
                        </Links>
                      </div>
                      <div>
                        <img src={item.image} alt="" />
                      </div>
                    </Blog>
                  </NewsContent>
                );
              })}
            </ContentWrapper>
          )}
        </NewsContainer>
        <NewsContainer>
          {activeP && (
            <ContentWrapper>
              <h1 style={{ marginBottom: "1em" }}>Publication</h1>
              <Row>
                {Publicationss.map((item, index) => {
                  return (
                    <Col sm={6} lg={6} xl={4} style={{ marginBottom: "3em" }}>
                      <PublicationCard>
                        <Img src={item.image} alt="" />
                        <div>
                          <DesktopH2
                            style={{
                              textTransform: "capitalize",
                              fontSize: "1.4rem !important",
                            }}
                          >
                            {item.title}
                          </DesktopH2>
                          <p>{item.paragraph}</p>
                          <Link to={`/events/publication/${item.id}`}>
                            {item.label}
                          </Link>
                        </div>
                      </PublicationCard>
                    </Col>
                  );
                })}
              </Row>
            </ContentWrapper>
          )}
        </NewsContainer>
        {/* END OF DESKTOP VIEW */}
      </Wrapper>
    </Section>
  );
};

export default News;
