import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";
import { useMediaQuery } from "@material-ui/core";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import style from "../styles/footer.module.css";
import { Link } from "react-router-dom";
import { FiTwitter, FiLinkedin, FiFacebook } from "react-icons/fi";
const SvgStyle = css`
  color: #fff;
  margin: 0 10px;
`;
const Facebook = styled(FiFacebook)`
  ${SvgStyle}
`;
const LinkedIn = styled(FiLinkedin)`
  ${SvgStyle}
`;
const Twitter = styled(FiTwitter)`
  ${SvgStyle}
`;

const Arrow = css`
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
    cursor: pointer;
  }
`;
const DownArrow = styled(FaAngleDown)`
  ${Arrow}
`;

const UpArrow = styled(FaAngleUp)`
  ${Arrow}
`;

function Footer(props) {
  const [newsOpen, setNewsOpen] = useState(true);
  const matches = useMediaQuery("(max-width:600px)");

  const handleNews = () => {
    if (matches) {
      setNewsOpen(!newsOpen);
    }
  };

  useEffect(() => {
    if (matches) {
      setNewsOpen(false);
    } else {
      setNewsOpen(true);
    }
  }, [matches]);

  const dates = new Date().getFullYear();

  return (
    <footer className={style.footerContainer}>
      <div className={style.footerBig}>
        <div className={style.footerNews} style={{ flex: "1" }}>
          <div onClick={handleNews} className={style.footerNewsMobile}>
            <h2> news</h2>
            {newsOpen ? <UpArrow /> : <DownArrow />}
          </div>
          {newsOpen && (
            <div style={{ height: "100%", overflow: "hidden" }}>
              <div className={style.footerNewsMobileContent}>
                <p>
                  The Federal Government has right to ensure that each State
                  Government pays debts guaranteed by the Federal Government.
                </p>
                <p>
                  A High Court Judge has no competence to deliver a judgment and
                  adjourn to a later date to give reasons for his judgment.
                </p>
                <p>
                  Alhaji Abdullahi Ibrahim, CON, SAN celebrates 50 years at the
                  Nigerian Bar Fellows of the Chartered Institute of Arbitrators
                </p>
              </div>
            </div>
          )}
        </div>
        <div style={{ color: "white" }}>
          <h2>Address</h2>
          <div>
            <div>
              <h3>Abuja FCT</h3>
              <p>
                45, Haile Selassie Street, off Thomas Sankara Street, Asokoro,
                Abuja F.C.T.
              </p>
            </div>
            <div>
              <h3>Lagos </h3>
              <p>
                No 2, Towry Street, off Catholic Mission Street, Lagos Island,
                Lagos State
                <br></br> P.O. Box 60114, Ikoyi, Lagos State
              </p>
            </div>
            <div>
              <h3>Kaduna </h3>
              <p>
                Top Floor, Abdulsalam Ziza House, A9, Mogadishu City Centre,
                Kaduna,<br></br> Kaduna State
              </p>
            </div>
          </div>
        </div>
        <div className={style.publications} style={{ paddingLeft: "40px" }}>
          Socials
          <div style={{ display: "flex" }}>
            <Link to="#">
              <Twitter />
            </Link>
            <Link to="#">
              <Facebook />
            </Link>
            <Link to="#">
              <LinkedIn />
            </Link>
          </div>
        </div>
      </div>
      <div className={style.footerSmall}>
        <p>© {dates} Abdullahi Ibrahim & Co. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
