import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import style from "../../styles/home.module.css";

import LazyLoad from "react-lazyload";
import Aos from "aos";
import "aos/dist/aos.css";

import "react-multi-carousel/lib/styles.css";

//Component
import Carousel from "../../components/carousel";
import CardSlider from "../../components/cardSlider";

import axios from "axios";
import { apis } from "../../api";
import Loader from "../../components/loader";

export default function Home(props) {
  const [loading, setLoading] = useState(false);
  const [listingDetails, setListingDetails] = useState([]);
  console.log(listingDetails);
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apis}/featured-partners`)
      .then((res) => {
        if (res) {
          setListingDetails(res?.data?.data);
          setLoading(false);
        }
      })
      .catch((res) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={style.homeContainer} id="back-to-top-anchor">
      <div className={style.carouselContainer}>
        <Carousel />
      </div>
      <div className={style.aboutAbu}>
        <Col xl={12}>
          <Row>
            <Col md={12} lg={12} xl={12} className={style.aboutCol}>
              <div>
                <h2>About Abdullahi Ibrahim & Co</h2>
                <p>
                  At AI&Co, we pride ourselves in our deep understanding of the
                  law and experience in key industry sectors. The Firm is a full
                  sector focused service law firm which provides its clients
                  with world class legal services delivered with very high
                  ethical standards. We have earned a reputation for delivering
                  excellent services which can compete at international levels.
                </p>
                <p>
                  We deliver solution driven services, and work in partnership
                  with our clients to understand their needs and provide lasting
                  solutions. Our vast knowledge in several sectors of law gives
                  us an edge over our contemporaries. With five (5) of our
                  partners being Senior Advocates of Nigeria, we have attained
                  enviable professional heights in the legal profession. Our
                  staff are well trained and work with the highest form of
                  integrity and professionalism.
                </p>
              </div>
            </Col>
            {/* <Col md={6} lg={6} xl={6}>
                            
                        </Col> */}
          </Row>
        </Col>
      </div>
      <div className={style.practiceBrief}>
        <h2>Our Practice Areas</h2>
        <CardSlider />
      </div>
      <div className={style.ourPhilosophy}>
        <div className={style.ourPhilosophyContent}>
          <h2>Our Philosophy</h2>
          <div>
            The Firm, Abdullahi Ibrahim & Co, has at its core the values of
            integrity and success, driven by honesty, professionalism, research
            and innovative approach to legal practice. The Firm has strong
            interests in advisory and advocacy services whilst boasting of
            seasoned and celebrated legal minds in the industry, and some of the
            best in modern commercial law practice including, Public
            Administrative Law, Alternative Dispute Resolution, Probate and
            Succession, Marine, Oil & Gas, Aviation, Stock
            Exchange,Telecommunications, Entertainment Law, Sports Law,
            Admiralty Law and Property Law.
          </div>
        </div>
      </div>
      <div className={style.meetCounselBrief}>
        <div>
          <h2>Meet our Partners</h2>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <Row className={style.meetCounselBriefImg}>
            <Col sm={12} xl={4} style={{ zIndex: "100" }}>
              <a href="/profile/partners&Adetunji%20Oyeyipo_200">
                <div>
                  <div>
                    <div className={style.meetCounselBriefName}>
                      <h5>
                        {listingDetails[0]?.firstname}{" "}
                        {listingDetails[0]?.lastname},{" "}
                        {listingDetails[0]?.title2}
                      </h5>
                    </div>
                    <LazyLoad height="100%">
                      <img
                        style={{ height: "550px", width: "400px" }}
                        src={listingDetails[0]?.pic_url}
                        alt=""
                      />
                    </LazyLoad>
                  </div>
                </div>
              </a>{" "}
            </Col>
            <Col sm={12} xl={4} style={{ zIndex: "100" }}>
              <a href="/profile/partners&Olabisi%20Soyebo_201">
                <div>
                  <div>
                    <div className={style.meetCounselBriefName}>
                      <h5>
                        {listingDetails[1]?.firstname}{" "}
                        {listingDetails[1]?.lastname},{" "}
                        {listingDetails[1]?.title2}
                      </h5>
                    </div>
                    <LazyLoad height="100%">
                      <img
                        style={{ height: "550px", width: "400px" }}
                        src={listingDetails[1]?.pic_url}
                        alt=""
                      />
                    </LazyLoad>
                  </div>
                </div>
              </a>{" "}
            </Col>
            <Col sm={12} xl={4} style={{ zIndex: "100" }}>
              <a href="/profile/partners&Rotimi%20Oguneso_202">
                <div>
                  <div>
                    <div className={style.meetCounselBriefName}>
                      <h5>
                        {listingDetails[2]?.firstname}{" "}
                        {listingDetails[2]?.lastname},{" "}
                        {listingDetails[2]?.title2}
                      </h5>
                    </div>
                    <LazyLoad height="100%">
                      <img
                        style={{ height: "550px", width: "400px" }}
                        src={listingDetails[2]?.pic_url}
                        alt=""
                      />
                    </LazyLoad>
                  </div>
                </div>
              </a>{" "}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}
