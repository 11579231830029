import PubOne from "../images/pubOne.png";

export const Newss = [];

export const Publicationss = [
  {
    id: "1",
    title: "Blurring the dividing line between Limitation and Jurisdiction",
    paragraph:
      "The fairly recent decision of the Supreme Court of Nigeria in the case of Elabanjo v Dawodu (2006) 15 NWLR (Pt.1001) 76 has brought out again certain...",
    label: "Read More",
    image: PubOne,
    link: "https://docs.google.com/document/d/1GSE8z9ryrm-MWVtsRbFW12Fh9ztfP0qZ/edit?usp=sharing&ouid=117980361442768312016&rtpof=true&sd=true",
  },
  {
    id: "2",
    title:
      "Appointment and Elevation of Judicial officers in Nigeria-Urgent Need For New Approach. ",
    paragraph:
      "This paper is not an attempt to criticise or condemn the present mode of appointment or...",
    label: "Read More",
    image: PubOne,
    link: "https://docs.google.com/document/d/11mWAmqViwRH4PQZiJTq2w5dIn9Z0zwmI/edit?usp=sharing&ouid=117980361442768312016&rtpof=true&sd=true",
  },
  {
    id: "3",
    title:
      "NDIC Re-Engagement with- Adjectival and Functional Law on 14th Day of December 2011",
    paragraph:
      "I must thank the organizers of this seminar for inviting me to deliver this paper on this all important topic or should I say myriad of topics...",
    label: "Read More",
    image: PubOne,
    link: "https://docs.google.com/document/d/1zlVLsHhA3heqkeZ-QnLuoFq3sQWoF29o/edit?usp=sharing&ouid=117980361442768312016&rtpof=true&sd=true",
  },
  {
    id: "4",
    title: "Skills and Techniques of Writing Effective Legal Opinion/Advice",
    paragraph:
      "During your time in the University, and at the Law School, you must have been taken through the task and acquired the skills of answering problem –type questions...",
    label: "Read More",
    image: PubOne,
    link: "https://docs.google.com/document/d/1ubUy13PcbNlndiXpAnNfP6KRHZ3_h2vc/edit?usp=sharing&ouid=117980361442768312016&rtpof=true&sd=true",
  },
];

export const PubData = {
  title: "Press 5",
  paragraph:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis in sodales fringilla volutpat. Viverra vitae non risus nunc tempus sed erat",
  label: "Read More",
  image: PubOne,
};
