import { Lagos, Abuja, Kaduna } from "../Components/SVG/Svg";

export const Address = [
  {
    id: 1,
    header: "ABUJA FCT",
    image: Abuja,
    address:
      "45, Haile Selassie Street, off Thomas Sankara Street, Asokoro, Abuja F.C.T",
    phone: "Telephone: 234 (0) 97822512.",
    email: " E-mail: infoabuja@abdullahiibrahimco.org",
  },
  {
    id: 2,
    header: "KADUNA",
    image: Kaduna,
    address:
      "Top Floor, Abdulsalam Ziza House, A9, Mogadishu City Centre, Kaduna, State State",
    phone: "Telephone: 234 (0) 62247997.",
    email: "E-mail: kaduna@abdullahiibrahimco.org",
  },
  {
    id: 3,
    header: "LAGOS ISLAND",
    image: Lagos,
    address:
      "No 2, Towry Street, off Catholic Mission Street, Lagos Island, Lagos State.P.O. Box 60114,Ikoyi, Lagos State",
    phone: " Telephone: 234 (0)1 2633971.",
    email: " E-mail: infolagos@abdullahiibrahimco.org",
  },
];
